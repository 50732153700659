<template>
  <div class="member">
    <el-container>
      <el-aside width="200px" class="asidemenu">
        <asideblock />
      </el-aside>
      <el-main>
        <headtable class="headmenu" />
        <el-card class="topbar">
          <p>群 {{ groupid }} 的管理员，</p>
          <p>欢迎使用 Pichubot 大学习管理功能</p>
        </el-card>
        <el-card class="box-card" style="min-height: 80%">
        <h2>成员编辑</h2>
        <el-divider></el-divider>
          <el-button size="small" @click="dialogFormVisible = true">新增成员</el-button>
          <el-divider direction="vertical"></el-divider>
          <span id="strictmode">严格模式</span>
          <el-switch
            v-model="strictmode"
            class="captcha-img"
            disabled
            @click.native="changestrictmode(strictmode)"
            style="cursor: pointer;">
            </el-switch>
          <memberTable ref="memberTable" />
        </el-card>
      </el-main>
    </el-container>
    <!-- 使用el-dialog组件 -->
    <el-dialog
      title="新增成员"
      :visible.sync="dialogFormVisible"
      >
      <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="left"
      :label-width="labelwidth"
      style="padding-left:10px;padding-right:10px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="QQ" prop="qq">
          <el-input v-model="form.qq" placeholder="请输入QQ号"></el-input>
        </el-form-item>
        <el-form-item label="校验链接" prop="url">
          <el-input v-model="form.url" placeholder="注：校验链接仅为严格模式下使用"></el-input>
        </el-form-item>
        <el-form-item align="right">
          <!-- 重置按钮 -->
          <el-button type="primary" @click="newmember('form')">提交</el-button>
          <el-button @click="resetForm('form')">重置</el-button> 
          <el-button @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import asideblock from '@/components/asideblock.vue'
import headtable from '@/components/headtable.vue'
import memberTable from '@/components/membertable.vue'
export default {
  name: 'Member',
  components: {
    asideblock,
    memberTable,
    headtable
  },
  data() {
    return {
      groupid: 1234567890,
      strictmode: false,
      dialogFormVisible: false,
      form: {
        name: '',
        qq: null,
        url: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        // qq 不能为空且必须为数字
        qq: [
          { required: true, message: '请输入QQ号', trigger: 'blur' },
          { validator: this.validateQQ, trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    validateQQ(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入QQ号'));
      } else {
        const re = /^[1-9][0-9]{4,11}$/;
        if (!re.test(value)) {
          callback(new Error('QQ号码格式错误'));
        } else {
          callback();
        }
      }
    },
    newmember(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.qq = parseInt(this.form.qq)
          this.$http({
            url: 'newmember',
            method: 'post',
            params: {
              groupid: this.$cookies.get("groupid"),
              value: this.$cookies.get("userId")
            },
            data: JSON.stringify(this.form)})
          .then(response => {
            if (response.data.code == 200) {
              this.$refs.memberTable.getMemberList()
              this.$message({
                message: '添加成功',
                type: 'success'
              });
              this.dialogFormVisible = false;
            } else {
              this.$message({
                message: '添加失败',
                type: 'error'
              });
            }
          })
          this.$refs[formName].resetFields();
        } else {
          this.$message.error('提交失败');
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async changestrictmode (value) {
      this.$confirm(`此操作将${!value ? '开启' : '关闭'}严格模式, 是否继续？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        this.$http({
          url: 'strictmode',
          method: 'post',
          params: {
            groupid: this.$cookies.get("groupid"),
            value: this.$cookies.get("userId")
          },
          data: JSON.stringify({"mode": !value})})
        .then(() => {
          this.strictmode = !value
          this.$message({
            message: `${!value ? '开启' : '关闭'}严格模式成功`,
            type: 'success'
          });
        })
        .catch((error) => {
          console.log(error);
        });
      }).catch(() => {
        console.log("cancel event")
      })
    },
    getStrictmode() {
      this.$http({
        url: 'getstrictmode',
        method: 'get',
        params: {
          groupid: this.$cookies.get("groupid"),
          value: this.$cookies.get("userId")
        },
      }).then(res => {
        // console.log(res);
        this.strictmode = res.data.data.mode
        // console.log(this.strictmode);
      })
      .catch((error) => {
        console.log(error);
      });
    },
  },
  created() {
    this.groupid = this.$cookies.get('groupid')
    this.getStrictmode();
    if (window.innerWidth < 768) {
      this.labelwidth = "70px";
    } else {
      this.labelwidth = "100px";
    }
  },
}
</script>

<style>
  #strictmode {
    font-size: 0.8em;
    margin-right: 10px;
  }
  .el-switch.is-disabled {
    opacity: 1;
  }
  .el-switch.is-disabled .el-switch__core, .el-switch.is-disabled .el-switch__label {
      cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    .asidemenu {
      display: none;
    }
    .headmenu {
      display: visible;
    }
  }

  @media screen and (min-width: 768px) {
    .asidemenu {
      display: visible;
    }
    .headmenu {
      display: none;
    }
  }
  .topbar p {
    display: inline-block;
    /*文字上下间距*/
    margin: 0.5em 0;
  }
</style>