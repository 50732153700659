<template>
  <div class="picture">
    <el-container>
      <el-aside width="200px" class="asidemenu">
        <asideblock />
      </el-aside>
      <el-main>
        <headtable class="headmenu" />
        <el-card class="topbar">
          <p>群 {{ groupid }} 的管理员，</p>
          <p>欢迎使用 Pichubot 大学习管理功能</p>
        </el-card>
        <el-card class="box-card" style="min-height: 80%">
        <h2>截图查看</h2>
        <el-divider></el-divider>
        <div class="toolbar">
          <el-button
          round
          :disabled="!picdata.can_download"
          id="downloadbtn"
          @click="getGroupPic">下载为压缩包</el-button>
          <span>完成进度：</span>
          <el-progress id="compprogress" :percentage="Math.round(picdata.comp_num/picdata.total*100)"></el-progress>
        </div>
          <el-row :gutter="20" style="overflow-x: hidden;">
            <el-col
            :xs="24" :sm="24" :md="12" :lg="8" :xl="6"
            v-for="i in picdata.group_pic"
            v-bind:key="i.qq"
            style="flex-wrap: wrap;">
              <el-card class="picturecard">
                <el-image
                :src="apiurl+i.img_path"
                :preview-src-list="[apiurl+i.img_path]"
                style="width: 100px; height: 100px"
                fit="contain">
                  <div slot="error" class="image-slot">
                    <el-image
                    :src="apiurl+'/groups/0/noimg.png'"
                    :preview-src-list="[apiurl+'/groups/0/noimg.png']"
                    style="width: 100px; height: 100px"
                    fit="contain">
                    </el-image>
                  </div>
                </el-image>
                <div class="picturecard_text">
                <p>姓名: {{ i.name }}</p>
                <p>QQ: {{ i.qq }}</p>
                </div>
              </el-card>
            </el-col>
          </el-row>
          
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import headtable from '@/components/headtable.vue'
import asideblock from '@/components/asideblock.vue'
export default {
  name: 'Picture',
  components: {
    asideblock,
    headtable
  },
  data() {
    return {
      // apiurl 为 this.$http.defaults.baseURL去除/api/
      apiurl: this.$http.defaults.baseURL.replace('/api/', ''),
      // apiurl: 'http://192.168.50.243:7001',
      groupid: 1234567890,
      picdata: {
        code: 200,
        message: "OK",
        can_download: false,
        comp_num: 0,
        total: 1,
        group_pic: []
      }
    }
  },
  methods: {
    listAllPic() {
      this.$http({
        url: 'listallpic',
        method: 'get',
        params: {
          groupid: this.$cookies.get("groupid"),
          value: this.$cookies.get("userId")
        }})
      .then(res => {
        this.picdata = res.data.data
      }).catch((err) => {
        console.log(err)
      })
    },
    getGroupPic() {
      this.$http({
        url: 'getgrouppic',
        method: 'get',
        params: {
          groupid: this.$cookies.get("groupid"),
          value: this.$cookies.get("userId")
        }})
      .then(res => {
        window.open(this.apiurl+'/'+res.data.data)
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  created() {
    this.groupid = this.$cookies.get('groupid')
    this.listAllPic()
  }
}
</script>

<style>
#strictmode {
  font-size: 0.8em;
  margin-right: 10px;
}

.el-row {
  display:flex;
  flex-wrap: wrap;
  overflow: auto;
  width: 100%;
}

.el-row .el-card {
  min-width: 100%;
  margin: 10px;
}

.picturecard {
  position:relative;
}

.picturecard .el-image {
  max-width: 100px;
  display: inline-block;
  top: 0px
}
.picturecard_text {
  display: inline-block;
  position: absolute;
  margin-left: 10px;
}

.toolbar {
  margin-bottom: 10px;
  margin-left: 10px;
}

.toolbar span {
  font-size: 0.9em;
}

#downloadbtn {
  margin-right: 20px;
  display: inline-block;
}
#compprogress {
  width: 200px;
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .asidemenu {
    display: none;
  }
  .headmenu {
    display: visible;
  }
}

@media screen and (min-width: 768px) {
  .asidemenu {
    display: visible;
  }
  .headmenu {
    display: none;
  }
}
.topbar p {
  display: inline-block;
  /*文字上下间距*/
  margin: 0.5em 0;
}
</style>