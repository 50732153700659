<template>
  <div class="membertable">
    <el-table
      :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)"
      ref="table"
      style="width: 100%">
      <el-table-column
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        prop="name"
        label="名称">
      </el-table-column>
      <el-table-column
        prop="qq"
        label="QQ">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope" class="tableedit">
          <el-button
            :size="buttonsize"
            class="tableedit-button"
            @click="handleEdit(scope.row)">编辑</el-button>
          <el-divider direction="vertical" class="tableedit-el-divider"></el-divider>
          <!-- <el-button
            slot="reference"
            size="mini"
            type="danger"
            @click="handleOutput(scope.row)">删除</el-button> -->
            <el-popconfirm
            type="danger"
            confirm-button-type="danger"
            confirm-button-text="删除"
            title="此操作将会删除该用户，确定删除吗？"
            icon="el-icon-error"
            @confirm="handleDelete(scope.row)">
              <el-button
                slot="reference"
                :size="buttonsize"
                type="danger">删除</el-button>
            </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top:10px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length">
    </el-pagination>

    <el-dialog title="编辑用户" :visible.sync="edit_item_visible">
      <!-- <el-divider></el-divider> -->
      <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="left"
      :label-width="labelwidth"
      class="editform">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="QQ" prop="qq">
          <el-input v-model="form.qq"></el-input>
        </el-form-item>
        <el-form-item label="校验链接" prop="url">
          <el-input v-model="form.url"></el-input>
        </el-form-item>
        <span>注：校验链接仅为严格模式下使用</span>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="edit_item_visible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style>
  @media screen and (max-width: 778px) {
    .tableedit-el-divider {
      display: none;
    }
    .tableedit {
      margin-bottom: 10px;
    }
    .el-dialog {
      width: 90%;
    }
    .tableedit-button {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
  @media screen and (min-width: 768px) {
    .editform {
      padding-left:20px
    }
    
  }
</style>

<script>
export default {
  name: "memberTable",
  data() {
    return {
      buttonsize: "small",
      labelwidth: "100px",
      pageSize: 10,
      currentPage: 1,
      tableData: [],
      edit_item_visible: false,
      form: {
        name: "",
        qq: NaN,
        url: "",
        oldqq: NaN
      },
      rules: {
        "name": [{
          required: true,
          message: "请输入名称",
          trigger: "blur"
        }],
        "qq": [
          { required: true, message: '请输入QQ号', trigger: 'blur' },
          { validator: this.validateQQ, trigger: 'blur' }]
      }
    }
  },
  methods: {
    validateQQ(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入QQ号'));
      } else {
        const re = /^[1-9][0-9]{4,11}$/;
        if (!re.test(value)) {
          callback(new Error('QQ号码格式错误'));
        } else {
          callback();
        }
      }
    },
    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.currentPage = 1;
        this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.currentPage = val;
    },
    handleEdit(row) {
      this.form.name = row.name;
      this.form.qq = row.qq;
      this.form.oldqq = row.qq;
      this.form.url = row.url;
      this.edit_item_visible = true;
    },
    getMemberList() {
      this.$http({
        url: 'listmember',
        method: 'get',
        params: {
        groupid: this.$cookies.get("groupid"),
        value: this.$cookies.get("userId"),
      }})
      .then((response) => {
        if (response.status == 200 && response.data.code == 200) {
          this.tableData = response.data.data;
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    handleDelete(row) {
      this.$http({
        url: 'delmember',
        method: 'post',
        params: {
          groupid: this.$cookies.get("groupid"),
          value: this.$cookies.get("userId")
        },
        data: JSON.stringify({"qq": row.qq})})
      .then((response) => {
        if (response.status == 200 && response.data.code == 200) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getMemberList();
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.qq = parseInt(this.form.qq);
          this.$http({
            url: 'editmember',
            method: 'post',
            params: {
              groupid: this.$cookies.get("groupid"),
              value: this.$cookies.get("userId")
            },
            data: JSON.stringify(this.form)})
          .then((response) => {
            if (response.status == 200 && response.data.code == 200) {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.getMemberList();
              this.edit_item_visible = false;
            }
            this.$refs[formName].resetFields();
          }).catch((error) => {
            console.log(error);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
  created() {
    this.getMemberList();
    if (window.innerWidth < 768) {
      this.labelwidth = "70px";
      this.buttonsize = "mini"
    }
  }
}
</script>